export default {
    methods: {
        async loadData(clientId, pageNo, pageSize, callback) {
            try {
                var rs = await this.$store.dispatch('getRecordList', {
                    clientID: clientId,
                    pageNo: pageNo,
                    pageSize: pageSize,
                })
                var list = rs.list || []
                var total = rs.total || 0
                callback && callback({
                    list: list,
                    total: total,
                }, null)
            } catch(rs) {
                callback && callback(null, rs.msg)
            }
        }
    }
}