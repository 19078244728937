<template>
    <div class="dialog record-detail" @click.self="dismissDialog">
        <div class="dialog-container">
            <div class="title-bar">
                <span class="title">病例詳情</span>
                <span class="close-dialog" @click="dismissDialog"></span>
            </div>
            <div class="content" v-if="detail">
                <div class="head-info">
                    <div class="row">
                        <span class="hint">主訴/症狀：</span>
                        <span class="value" :data-value="detail.caseHistory.syndrome" v-text="detail.caseHistory.syndrome || '--'"></span>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div>
                                <span class="hint">舌：</span>
                                <span class="value" :data-value="detail.caseHistory.tongue" v-text="detail.caseHistory.tongue || '--'"></span>
                            </div>
                            <div>
                                <span class="hint">脈：</span>
                                <span class="value" :data-value="detail.caseHistory.pulse" v-text="detail.caseHistory.pulse || '--'"></span>
                            </div>
                        </div>
                        <div class="col">
                            <div>
                                <span class="hint">大便：</span>
                                <span class="value" :data-value="detail.caseHistory.defecate" v-text="detail.caseHistory.defecate || '--'"></span>
                            </div>
                            <div>
                                <span class="hint">小便：</span>
                                <span class="value" :data-value="detail.caseHistory.piss" v-text="detail.caseHistory.piss || '--'"></span>
                            </div>
                        </div>
                        <div class="col">
                            <div>
                                <span class="hint">飲食：</span>
                                <span class="value" :data-value="detail.caseHistory.appetite" v-text="detail.caseHistory.appetite || '--'"></span>
                            </div>
                            <div>
                                <span class="hint">補充：</span>
                                <span class="value" :data-value="detail.caseHistory.otherremark" v-text="detail.caseHistory.otherremark || '--'"></span>
                            </div>
                        </div>
                    </div>
                    <div class="row revert">
                        <span class="hint">診斷：</span>
                        <span class="value" :data-value="detail.caseHistory.diagnosis" v-text="detail.caseHistory.diagnosis || '--'"></span>
                    </div>
                    <div class="row revert">
                        <span class="hint">醫囑：</span>
                        <span class="value" :data-value="detail.caseHistory.doctoradvice" v-text="detail.caseHistory.doctoradvice || '--'"></span>
                    </div>
                </div>
                <div class="prescription-titles">
                    <span @click="changeTitleIndex(index)" :class="{'title': true, 'current': mIndex == index}" v-for="(item, index) in detail.prescriptionInfo" :key="index" v-text="titleList[index]"></span>
                </div>
                <div class="prescription-list">
                    <div :class="{'prescription-item': true, 'current': mIndex == index}" v-for="(prescription, index) in detail.prescriptionInfo" :key="index">
                        <div class="header">
                            <span class="header-item take-type" v-text="prescription.takeTime"></span>
                            <span class="header-item take-times">每日{{prescription.takeByDay}}次</span>
                            <span class="take-days">用藥{{prescription.doseDays}}天</span>
                            <span class="space"></span>
                            <span class="other-info">(藥物類別：{{prescription.medicinalType}})</span>
                        </div>
                        <div class="drug-list">
                            <div class="drug-item" v-for="(medicinal, _i) in prescription.medicinalList" :key="_i">
                                <span class="drug-name" v-text="medicinal.MedicinalName"></span>
                                <span class="drug-count" v-text="medicinal.MedicinalTotalWeight.toFixed(2) + '克'"></span>
                            </div>
                        </div>
                        <div class="note" v-text="prescription.remark && ('備註：' + prescription.remark)"></div>
                    </div>
                </div>
            </div>
        </div>
        <loading v-if="loading"/>
    </div>
</template>

<script>
import Loading from '@/layouts/Loading.vue'
export default {
    components: {
        Loading
    },
    props: {
        id: Number,
        callback: Function,
    },
    data() {
        return {
            loading: true,
            mIndex: 0,
            detail: null,
            titleList: [
                '處方一',
                '處方二',
                '處方三',
                '處方四',
                '處方五',
                '處方六',
                '處方七',
                '處方八',
                '處方九',
            ]
        }
    },
    mounted: function(){
        this.onRefresh()
    },
    methods: {
        dismissDialog: function() {
            this.callback && this.callback()
        },
        onBooingBtnClick: function(){
            this.callback && this.callback(true)
        },
        changeTitleIndex: function(i) {
            this.mIndex = i
        },
        async onRefresh() {
            this.loading = true
            this.$store.dispatch('getRecordDetail', {
                clientId: this.$store.getters.clientId,
                clientRegisterId: this.id,
            }).then((data)=>{
                this.loading = false
                this.detail = data
            }).catch((error)=>{
                this.loading = false
                this._toast.warning(this, error)
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .dialog {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 1000;
        background-color: rgba(4, 58, 61, 0.3);
        overflow: hidden;
    }

    .dialog > .dialog-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 936px;
        height: 640px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .dialog> .dialog-container .title-bar {
        display: inline-block;
        height: 46px;
        line-height: 46px;
        background-color: #2B99A6;
        padding-left: 30px;
        font-weight: bold;
        font-size: 20px;
        color: #fff;
    }

    .dialog > .dialog-container .title-bar .close-dialog {
        float: right;
        width: 46px;
        height: 46px;
        position: relative;
        background-color: #36C4D0;
        cursor: pointer;
    }

    .dialog > .dialog-container .title-bar .close-dialog::after {
        width: 20px;
        height: 20px;
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-image: url(../assets/imgs/pc/img_cha_white@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .dialog.record-detail .prescription-titles {
        height: 40px;
        display: flex;
        flex-direction: row;
        margin: 20px 30px 0px 30px;
    }

    .dialog.record-detail .prescription-titles > .title {
        padding-left: 14px;
        padding-right: 12px;
        height: 40px;
        box-sizing: border-box;
        border: 1px solid transparent;
        line-height: 40px;
        color: #969696;
        font-size: 18px;
        font-weight: 400;
        border-bottom: 0px;
        cursor: pointer;
    }

    .dialog.record-detail .prescription-titles > .title.current {
        border-color: #E3EFF2;
        color: #231F20;
        font-size: 20px;
        font-weight: bold;
    }

    .dialog.record-detail .prescription-list {
        display: flex;
        flex-direction: column;
        overflow-x: scroll;
        overflow-y: hidden;
        margin: 0 30px;
        border-top: 1px solid #E3EFF2;
    }

    .dialog.record-detail .prescription-list > .prescription-item {
        display: none;
        flex-direction: column;
        box-sizing: border-box;
    }

    .dialog.record-detail .prescription-list > .prescription-item.current {
        display: flex;
    }

    .dialog.record-detail .prescription-list > .prescription-item > .header {
        display: flex;
        flex-direction: row;
        margin: 40px 0px 20px 0px;
        height: 18px;
        line-height: 18px;
        color: #231F20;
        font-size: 18px;
        font-weight: 400;
    }

    .dialog.record-detail .prescription-list > .prescription-item > .header > .header-item {
        padding-right: 55px;
        height: 18px;
        position: relative;
    }

    .dialog.record-detail .prescription-list > .prescription-item > .header > .header-item::after {
        content: '';
        position: absolute;
        right: 27px;
        top: 4px;
        height: 10px;
        width: 1px;
        background-color: #E8E8E8;
    }

    .dialog.record-detail .prescription-list > .prescription-item > .header > .space {
        flex: 1;
    }

    .dialog.record-detail .prescription-list > .prescription-item > .header > .other-info {
        color: #969696;
    }

    .dialog.record-detail .prescription-list > .prescription-item > .note {
        color: #969696;
        font-size: 18px;
        line-height: 19px;
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        justify-content: flex-start;
    }

    .dialog.record-detail .prescription-list > .prescription-item > .drug-list {
        display: grid;
        grid-template-columns: 141px 141px 141px 141px 141px 141px;
        grid-template-rows: 50px 50px 50px;
        grid-auto-flow: row;
        grid-row-gap: 6px;
        grid-column-gap: 6px;
    }

    .dialog.record-detail .prescription-list > .prescription-item > .drug-list > .drug-item {
        height: 50px;
        line-height: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0px 16px;
        background-color: #EFF7F8;
    }

    .dialog.record-detail .doctor-advice {
        color: #231F20;
        font-size: 16px;
        font-weight: bold;
        height: 16px;
        line-height: 16px;
        display: flex;
        flex-direction: row;
        margin: 80px 30px 0px 30px;
        justify-content: flex-start;
    }

    .dialog.record-detail .doctor-advice.hide {
        display: none;
    }

    .head-info {
        display: flex;
        flex-direction: column;
        margin: 40px 30px 0px 30px;
    }

    .head-info > .row {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
    }

    .head-info > .row > .hint {
        line-height: 16px;
        font-size: 16px;
        color: #636363;
        font-weight: 400;
    }

    .head-info > .row > .value {
        font-weight: bold;
        color: #231F20;
        font-size: 16px;
        line-height: 16px;
    }

    .head-info > .row.revert > .hint {
        color: #231F20;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
    }

    .head-info > .row.revert > .value {
        color: #231F20;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px; 
    }



    .head-info > .row > .col > div > .hint {
        color: #636363;
        font-size: 16px;
        font-weight: 400;
    }

    .head-info > .row > .col > div > .value {
        color: #231F20;
        font-size: 16px;
        font-weight: bold;
    }

    .head-info > .row > .col > div > .value[data-value=''],
    .head-info > .row.revert > .value[data-value=''],
    .head-info > .row > .value[data-value=''] {
        font-weight: 400;
        color: #969696;
        font-size: 16px;
        line-height: 16px;
    }

    .head-info > .row > .col {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    .head-info > .row > .col > div {
        height: 28px;
        background-color: #EFF7F8;
        margin-right: 6px;
        margin-bottom: 6px;
        line-height: 28px;
        padding-left: 13px;
        box-sizing: border-box;
    }
</style>