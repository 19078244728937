<template>
	<div class="tab-view">
		<div class="menus">
			<router-link to="/user/booking/order" class="menu">我的預約</router-link>
            <router-link to="/user/booking/record" class="menu current">就診記錄</router-link>
		</div>
		<div class="tabview-content">
            <div class="record-list">
                <ul class="record-head">
                    <li class="code">編號</li>
                    <li class="result">診斷</li>
                    <li class="clinic-name">診所名稱</li>
                    <li class="doctor-name">醫師</li>
                    <li class="create-time">時間</li>
                    <li class="operate">操作</li>
                </ul>
                <ul class="record-item" v-for="(record, i) in recordList" :key="i">
                    <li class="code" v-text="record.clientRegisterId"></li>
                    <li class="result" v-text="record.diagnosis"></li>
                    <li class="clinic-name" v-text="record.clinicName"></li>
                    <li class="doctor-name" v-text="record.doctorName"></li>
                    <li class="create-time" v-text="record.registerDate"></li>
                    <li class="operate"><a href="javascript:;" class="btn-detail" @click="showDetail(record.clientRegisterId)">詳情</a> </li>
                </ul>
            </div>
            <split-page v-if="visible" :initData="{pageNo, pageSize, total}" :onPageChanged="onPageChanged"/>
            <loading v-if="loading"/>
        </div>
        <prescription-detail v-if="detailVisible" :id="detailId" :callback="onClose"/>
	</div>
</template>

<script>
    import record from '@/views/mixins/record'
    import Loading from '@/layouts/Loading.vue'
    import SplitPage from '@/layouts/SplitPage.vue'
    import PrescriptionDetail from '@/layouts/PrescriptionDetail.vue'
	export default {
        mixins: [
            record,
        ],
        components: {
            Loading,
            SplitPage,
            PrescriptionDetail,
        },
		mounted: function(){
            this.pathName = this.$route.path
            this.onRefresh()
		},
		data() {
            return {
                visible: false,
                detailVisible: false,
                detailId: null,
                style: '',
                total: 0,
                loading: true,
                pageNo: 1,
                pageSize: 10,
                recordList: [],
                pages: [{
                    index: 1,
                    key: '1',
                    disable: false
                }]
            }
        },
        methods: {
            onPageChanged: function(pageNo){
                this.pageNo = pageNo
                this.onRefresh()
            },
            onRefresh: function(){
                this.loading = true
                this.loadData(this.$store.getters.clientId, this.pageNo, this.pageSize, (data, error)=>{
                    this.loading = false
                    if(data) {
                        this.recordList = data.list
                        this.total = data.total
                        this.visible = this.total > 0

                        this.detailId = this.$route.params.id
                        if(this.detailId) {
                            this.detailVisible = true
                        }
                    } else {
                        if(error) {
                            this._toast.warning(this, error)
                        }
                    }
                })
            },
            showDetail: function(id){
                this.detailId = id
                this.detailVisible = true
                this.style = document.body.getAttribute('style') || ''
                document.body.setAttribute('style', this.style + 'overflow: hidden;')
            },
            onClose: function(){
                this.detailVisible = false
                document.body.setAttribute('style', this.style)
            }
        }
	};
</script>

<style lang="scss" scoped>
    .tab-view {
		width: 1000px;
		display: flex;
		flex-direction: row;
		margin: 0px auto 40px auto;
		position: relative;
	}

	.tab-view > .menus {
		display: flex;
		flex-direction: column;
		width: 164px;
		margin-right: 10px;
		margin-top: -1px;
	}

	.tab-view > .menus > .menu {
		display: block;
		width: 164px;
		height: 50px;
		margin-top: 1px;
		background-color: #fff;
		line-height: 50px;
		text-align: center;
		font-weight: 400;
		color: #969696;
		font-size: 16px;
	}

	.tab-view > .menus > .menu.current {
		color: #36C4D0;
		font-weight: bold;
		position: relative;
	}

	.tab-view > .menus > .menu.current::after {
		content: '';
		position: absolute;
		right: 10px;
		top: 20px;
		width: 7px;
		height: 10px;
		background-image: url(../../../../assets/imgs/pc/user/img_more@2x.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.tab-view > .tabview-content {
		flex: 1;
		display: flex;
		flex-direction: column;
		background-color: #fff;
		min-height: 466px;
        box-sizing: border-box;
        position: relative;
        padding-bottom: 60px;
    }

    .record-list {
        display: flex;
        flex-direction: column;
    }

    .record-list > .record-head,
    .record-list > .record-item {
        height: 36px;
        line-height: 36px;
        display: flex;
        flex-direction: row;
        font-weight: 400;
        color: #000000;
        font-size: 14px;
        background-color: #fff;
        cursor: pointer;
    }

    .record-list > .record-item:nth-of-type(2n-1) {
        background-color: #F6F6F6;
    }

    .record-list > .record-item:hover {
        background-color: rgba(54, 196, 208, 0.3);
    }

    .record-list > .record-head {
        color: #fff;
        background-color: #6EAFB3;
    }

    .record-list > .record-head > .code,
    .record-list > .record-item > .code {
        flex: 1;
        text-align: center;
    }

    .record-list > .record-head > .result,
    .record-list > .record-item > .result {
        flex: 2;
        text-align: center;
    }

    .record-list > .record-head > .clinic-name,
    .record-list > .record-item > .clinic-name {
        flex: 3;
        text-align: center;
    }

    .record-list > .record-head > .doctor-name,
    .record-list > .record-item > .doctor-name {
        flex: 1;
        text-align: center;
    }

    .record-list > .record-head > .create-time,
    .record-list > .record-item > .create-time {
        flex: 2;
        text-align: center;
    }


    .record-list > .record-head > .operate,
    .record-list > .record-item > .operate {
        flex: 1.5;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .record-list > .record-item > .operate > .btn-detail {
        color: #36C4D0;
        text-decoration: underline;
        font-size: 14px;
        line-height: 14px;
    }

	.split-pages-container {
		position: absolute;
		bottom: 20px;
		right: 20px;
		display: flex;
		flex-direction: row;
		margin-top: 25px;
		justify-content: flex-end;
	}

	.split-pages-container > .split-pages {
		display: flex;
		flex-direction: row;
	}

	.split-pages-container > .split-pages > .split-page {
		display: inline-block;
		width: 22px;
		height: 22px;
		border-radius: 50%;
		color: #969696;
		font-size: 14px;
		line-height: 22px;
		text-align: center;
		font-weight: 400;
		margin-right: 20px;
		background-color: #DDDDDD;
		cursor: pointer;
	}

	.split-pages-container > .split-pages > .split-page.more {
		line-height: 16px;
	}

	.split-pages-container > .split-pages > .split-page:hover,
	.split-pages-container > .split-pages > .split-page.current {
		color: #fff;
		background-color: #36C4D0;
		font-weight: bold;
	}

	.split-pages-container > .btn-page-prev,
	.split-pages-container > .btn-page-next {
		height: 20px;
		width: 56px;
		text-align: center;
		line-height: 20px;
		font-weight: 300;
		color: #636363;
		background-color: #DDDDDD;
		border-radius: 4px;
		font-weight: 400;
		font-size: 14px;
		cursor: pointer;
	}

	.split-pages-container > .btn-page-next {
		margin-left: 24px;
		color: #231F20;
	}
</style>
